* {
    margin:0;
    padding: 0;
}


.title {
    width: 100%;
    height: 84vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.titleName {
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: 30px;
}

.titleDescription {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 10vh;
    padding: 10px;
    margin: 10px;
}

.titleOneline {
    font-size: 2.5em;
    font-weight: 300;
}

@keyframes fadeInUp {
    from {
        transform: translate3d(0,40px,0)
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}

@-webkit-keyframes fadeInUp {
    from {
        transform: translate3d(0,40px,0)
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}

.animated {
    animation-duration: 0.5s;
    animation-fill-mode: both;
    -webkit-animation-duration: 0.5s;
    -webkit-animation-fill-mode: both
}

.animatedFadeInUp {
    opacity: 0
}

.fadeInUp {
    opacity: 0;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
}