* {
    margin:0;
    padding: 0;
}

.projects {
    width: 100%;
    height: 84vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
}

.thumbnailContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    overflow-y: hidden;
    overflow-x: none;
    min-height: 80px;
}