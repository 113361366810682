* {
    margin:0;
    padding: 0;
}

main {
    overflow-y: scroll;
}

.dark-mode {
    background-color: #1a1919;
    /* color: #999; */
    color: white;
}

.dark-mode #bulb_body {
    fill: yellow;
}

.dark-mode #bulb_body:hover {
    fill: #1a1919;
}

.dark-mode .navlink {
    color: white;
}

.dark-mode .logoName {
    color: white;
}

#site-container{
    height: 100vh;
}

.ball {
    z-index: -1;
    position: absolute;
    border-radius: 100%;
    opacity: 0.7;
  }