#project-container {
    width: 95vw;
    height: 75vh;
    display: grid;
    grid-template-rows: 1fr 3fr 1fr 3fr;
    grid-template-columns: 1fr 1fr 1fr;
}

#project-title {
    grid-area: 1 / 1 / auto / span 2;
    justify-self: flex-start;
    text-align: initial;
    padding-top: 1vh;
    padding-left: 3vw;
    font-size: 4em;
}

#project-description {
    grid-area: 2 / 1 / auto / span 2;
    justify-self: flex-start;
    text-align: initial;
    padding: 1vh 3vw 2vh 3vw;
    font-size: 1em;
}

#project-github {
    grid-area: 3/1;
    justify-self: flex-start;
    align-self:flex-start;
    padding-top: 2vh;
    text-align: start;
    padding-left: 3vw;
}

#project-github-link {
    text-decoration: none;
    color: inherit;
}

#project-github-link:hover {
    text-decoration: underline;
}
