* {
    margin:0;
    padding: 0;
    font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu;
}

  .container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 8vh;
    min-height: 45px;
  }

  .nav {
    display: flex;
    justify-content: space-around;
    width: 35%;
  }

  .navlink {
    text-decoration: none;
    color: #3D3D3D;
    font-weight:50rem;
  }
  
  .navlink:hover {
    text-decoration: underline;
  }

  #bulb-svg {
    min-height: 8vh;
    width: 50px;
    height: 150px;
    // animation: shake 0.5s; 
    // animation-iteration-count: 1; 
  }

#bulb_body:hover {
  fill: yellow;
}

.logoContainer {
  display: flex;
  align-items: center;
  width: 20%;
}

.logoName {
  font-weight: 5rem;
  text-decoration: none;
  color: black  
}

.logoName:hover {
  text-decoration: underline;
}

.shake {
  animation: shake 0.5s; 
  animation-iteration-count: 1;
}

@keyframes shake {
  0% { transform: rotate(0deg); }
  10% { transform: rotate(-15deg); }
  20% { transform: rotate(+15deg); }
  30% { transform: rotate(0deg); }
  40% { transform: rotate(15deg); }
  50% { transform: rotate(-15deg); }
  60% { transform: rotate(0deg); }
  70% { transform: rotate(-15deg); }
  80% { transform: rotate(15deg); }
  90% { transform: rotate(0deg); }
  100% { transform: rotate(-15deg); }
}