* {
    margin:0;
    padding: 0;
}
.articles {
    width: 100%;
    height: 84vh;
    /* background-color: orange; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}