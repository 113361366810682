* {
  margin: 0;
  padding: 0;
}
#section-container {
  width: 100%;
  min-height: 600px;
  display: flex;
  flex-direction: column;
}

#content-container {
  display: flex;
  justify-content: center;
  width: 80%;
  align-self: center;
}

#title-container {
  width: 80%;
  padding-top: 10vh;
  padding-left: 3vw;
  align-self: center;
}

.aboutTitle {
  font-size: 4rem;
  font-weight: 300;
}

.aboutProfile {
  padding-top: 3vh;
  padding-left: 3vw;
  width: 40%;
  min-width: 300px;
}

.about-subtitle {
  text-align: start;
  font-size: 2rem;
  font-weight: 400;
  text-decoration: underline;
  padding: 0.5rem;
}

#about-skills {
  padding-left: 7vw;
  min-height: 300px;
}

#about-experience {
  text-align: start;
  min-height: 150px;
  padding-left: 3vw;
}

#cv-container {
  width: 80%;
  align-self: center;
}

#about-cv {
  font-weight: 400;
  padding-top: 2vh;
  padding-left: 3vw;
}

#about-cv-link {
  color: inherit;
}

#about-cv-link:hover {
  font-style: italic;
}

@keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@-webkit-keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.animated {
  animation-duration: 0.5s;
  animation-fill-mode: both;
  -webkit-animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
}

.animatedFadeInUp {
  opacity: 0;
}

.fadeInUp {
  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
}

@media screen and (max-width: 850px) {
  #content-container {
    flex-direction: column;
  }
  #about-cv {
    padding-top: 1vh;
  }
}
